import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder } from "../styles/ui.styles";
import { DoubleTitle } from "../styles/ui.styles";
import type { PressArticlesQuery } from "../../graphql-types";
import type { IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";

interface Props {
  data: PressArticlesQuery;
}

const Press: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SEO title={"Press"} description={"Press"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <DoubleTitle normalTitle="Press" largeTitle="PRESS" />

          {data.allPressJson.edges.map(({ node }, index) => {
            // do not show if it is a dummy article
            if (node.title?.includes("dummy")) return;
            return (
              <article key={node.id}>
                <a
                  href={node?.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ textDecoration: "none" }}
                >
                  <Card reverse={index % 2 === 0 ? false : true}>
                    <CardImageWrapper>
                      {node?.heroImage ? (
                        <GatsbyImage
                          style={{ width: "100%", height: "100%" }}
                          image={
                            getImage(
                              node.heroImage as ImageDataLike
                            ) as IGatsbyImageData
                          }
                          alt={node?.heroImageCredits || "image"}
                        />
                      ) : (
                        <StaticImage
                          style={{ width: "100%", height: "100%" }}
                          src={"../images/fastrrr_logo_fekete.png"}
                          objectFit={"contain"}
                          alt={"Fastrrr logo"}
                        />
                      )}
                    </CardImageWrapper>
                    <CardInner>
                      <div>
                        <h3>{node?.title}</h3>
                      </div>
                      <div>
                        <p>Published: {node?.date}</p>
                        <p>Source: {node?.source}</p>
                      </div>
                      <ImageCredits>{node?.heroImageCredits}</ImageCredits>
                    </CardInner>
                  </Card>
                </a>
              </article>
            );
          })}
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Press;

// * GraphQL query

export const data = graphql`
  query PressArticles {
    allPressJson(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          date(formatString: "DD-MM-YYYY")
          heroImageCredits
          title
          source
          link
          heroImage {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
`;

const CardImageWrapper = styled.div``;

const ImageCredits = styled.div`
  display: inline-block;
  width: calc(100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 0 11px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--color-middle-grey);
`;

const Card = styled.div<{ reverse?: boolean }>`
  position: relative;
  margin: 32px 0;
  background: #fff;
  width: 100%;
  height: 300px;
  display: flex;
  border-radius: 20px;
  align-items: stretch;
  flex-direction: ${(props) => (props.reverse ? `row-reverse` : `row`)};
  box-shadow: var(--shadow);

  ${CardImageWrapper} {
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-top-left-radius: ${(props) => (props.reverse ? `0` : `20px`)};
    border-bottom-left-radius: ${(props) => (props.reverse ? `0` : `20px`)};
    border-top-right-radius: ${(props) => (props.reverse ? `20px` : `0`)};
    border-bottom-right-radius: ${(props) => (props.reverse ? `20px` : `0`)};
  }

  ${ImageCredits} {
    text-align: ${(props) => (props.reverse ? `right` : `left`)};
  }

  @media (max-width: 664px) {
    width: 100%;
    height: 600px;
    flex-direction: column;

    ${CardImageWrapper} {
      width: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 0;
    }
  }
`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  div:first-child {
    min-height: 0;
    overflow: hidden;
    /* text-overflow: ellipsis;
    -webkit-line-clamp: 3; */
    flex: 1 1 auto;

    h3 {
      margin: 0;
      display: inline-block;
    }
  }

  h3 {
    color: #000;
    line-height: unset;
  }

  div p {
    margin: 0;
  }

  p {
    text-decoration: none;
    color: var(--color-dark-grey);
  }
`;
